@use '@angular/material' as mat;

:root {
    --color-primary: #6a51a4;
    --color-on-primary: white;
}

@include mat.core();

$theme-primary-color-map: (
    50: var(--color-primary),
    100: var(--color-primary),
    200: var(--color-primary),
    300: var(--color-primary),
    400: var(--color-primary),
    500: var(--color-primary),
    600: var(--color-primary),
    700: var(--color-primary),
    800: var(--color-primary),
    900: var(--color-primary),
    A100: var(--color-primary),
    A200: var(--color-primary),
    A400: var(--color-primary),
    A700: var(--color-primary),
    contrast: (
        50: var(--color-on-primary),
        100: var(--color-on-primary),
        200: var(--color-on-primary),
        300: var(--color-on-primary),
        400: var(--color-on-primary),
        500: var(--color-on-primary),
        600: var(--color-on-primary),
        700: var(--color-on-primary),
        800: var(--color-on-primary),
        900: var(--color-on-primary),
        A100: var(--color-on-primary),
        A200: var(--color-on-primary),
        A400: var(--color-on-primary),
        A700: var(--color-on-primary),
    ),
);

$app-primary: mat.define-palette($theme-primary-color-map);
$app-accent: mat.define-palette($theme-primary-color-map, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme(
    (
        color: (
            primary: $app-primary,
            accent: $app-accent,
            warn: $app-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);
$app-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: $app-primary,
            accent: $app-accent,
            warn: $app-warn,
        ),
    )
);

@include mat.core-theme($app-theme);
@include mat.button-theme($app-theme);

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #333333;
    }
    @include mat.core-color($app-dark-theme);
    @include mat.button-color($app-dark-theme);
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    background-color: var(--background-color);
    font-family:
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji;
}
